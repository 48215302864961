@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
#top .p-mainVisual {
  overflow: hidden;
  position: relative; }
  #top .p-mainVisual__scroll {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 105px;
    right: -55px;
    transform: rotate(90deg); }
    @media screen and (max-width: 768px) {
      #top .p-mainVisual__scroll {
        display: none; } }
    #top .p-mainVisual__scroll-txt {
      color: #FFFFFF;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.05em;
      display: inline-block;
      margin-right: 12px;
      text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4); }
    #top .p-mainVisual__scroll-bar {
      display: inline-block;
      width: 100px;
      height: 2px;
      background-color: #FFFFFF;
      position: relative;
      overflow: hidden;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4); }
      #top .p-mainVisual__scroll-bar:before {
        content: '';
        width: 50%;
        height: 2px;
        position: absolute;
        top: 0;
        left: -50%;
        display: inline-block;
        background-color: #C6040E; }

#top .p-loopSlide {
  display: flex;
  width: 100vw;
  overflow: hidden;
  height: calc( 100vh - 88px); }
  @media screen and (max-width: 1024px) {
    #top .p-loopSlide {
      height: calc( 100vh - 62px); } }
  @media screen and (max-width: 568px) {
    #top .p-loopSlide {
      height: 507px; } }
  #top .p-loopSlide ul {
    padding: 0;
    width: 400%;
    display: flex;
    flex-shrink: 0; }
    @media screen and (max-width: 768px) {
      #top .p-loopSlide ul {
        width: 960%; } }
    #top .p-loopSlide ul:first-child {
      animation: slide1 140s -70s linear infinite; }
    #top .p-loopSlide ul:last-child {
      animation: slide2 140s linear infinite; }
    #top .p-loopSlide ul li {
      background-size: cover;
      display: inline-block;
      width: 100%;
      min-width: 150px;
      list-style: none;
      text-align: center; }
      @media screen and (max-width: 568px) {
        #top .p-loopSlide ul li {
          height: 507px; } }
      #top .p-loopSlide ul li img {
        display: block;
        width: 100%;
        height: auto; }
        @media screen and (max-width: 568px) {
          #top .p-loopSlide ul li img {
            width: auto;
            height: 100%; } }

#top .p-catch {
  position: absolute;
  bottom: 0;
  left: 30px; }
  @media screen and (max-width: 568px) {
    #top .p-catch {
      left: 15px; } }
  #top .p-catch__pc {
    vertical-align: bottom; }
    @media screen and (max-width: 1024px) {
      #top .p-catch__pc {
        width: 100%;
        vertical-align: bottom; } }
    @media screen and (max-width: 768px) {
      #top .p-catch__pc {
        display: none; } }
  #top .p-catch__sp {
    vertical-align: bottom;
    display: none; }
    @media screen and (max-width: 768px) {
      #top .p-catch__sp {
        display: block;
        width: calc(100% - 30px); } }

@keyframes slide1 {
  0% {
    transform: translateX(100%); }
  to {
    transform: translateX(-100%); } }

@keyframes slide2 {
  0% {
    transform: translateX(0); }
  to {
    transform: translateX(-200%); } }

#top #wrapper {
  padding: 0 0; }
  @media screen and (max-width: 1168px) {
    #top #wrapper {
      padding: 60px 0 0; } }

#top .l-col2 .c-ttl-2 {
  margin-bottom: 10px; }

#top #newslist {
  padding-bottom: 0; }
  @media screen and (max-width: 768px) {
    #top #newslist .c-newslist__wrap {
      background-color: #F5F5F5;
      padding: 15px 0 40px;
      border-radius: 10px; } }
  @media screen and (max-width: 768px) {
    #top #newslist .c-newslist dl dt {
      margin-bottom: 5px; } }
  #top #newslist .c-newslist dl dd {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

#top .p-about .l-col2__detail {
  position: relative; }
  #top .p-about .l-col2__detail:before {
    content: '';
    display: block;
    background: url("/inc/image/top/bg_track_line_right.png") no-repeat center/contain;
    width: 402px;
    height: 335px;
    position: absolute;
    top: 68%;
    left: 380px;
    transform: translateY(-50%);
    z-index: -1; }
    @media screen and (max-width: 1024px) {
      #top .p-about .l-col2__detail:before {
        left: 150px; } }
    @media screen and (max-width: 768px) {
      #top .p-about .l-col2__detail:before {
        left: 270px;
        width: 100%;
        height: 100%; } }
    @media screen and (max-width: 568px) {
      #top .p-about .l-col2__detail:before {
        left: 80px;
        width: 201px;
        height: 167.5px; } }

#top .p-reason .l-col2__detail {
  position: relative; }
  #top .p-reason .l-col2__detail:before {
    content: '';
    display: block;
    background: url("/inc/image/top/bg_track_line_left.png") no-repeat center/contain;
    width: 402px;
    height: 335px;
    position: absolute;
    top: 68%;
    left: -195px;
    transform: translateY(-50%);
    z-index: -1; }
    @media screen and (max-width: 1024px) {
      #top .p-reason .l-col2__detail:before {
        left: -205px; } }
    @media screen and (max-width: 768px) {
      #top .p-reason .l-col2__detail:before {
        width: 100%;
        height: 100%; } }
    @media screen and (max-width: 568px) {
      #top .p-reason .l-col2__detail:before {
        left: 67px;
        width: 201px;
        height: 167.5px; } }
